// src/components/Header.jsx

import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faHeart, faEnvelope, faSearch, faMusic } from '@fortawesome/free-solid-svg-icons';
import { fetchFavoriteCount, fetchBasketCount } from '../services/api';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage as storageConfig } from '../firebase-config';
import './Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const Header = ({ onTogglePopup, isLoggedIn, isAdmin, username, onLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeNavItem, setActiveNavItem] = useState(location.pathname);
  const [searchQuery, setSearchQuery] = useState('');
  const [favoriteCount, setFavoriteCount] = useState(0);
  const [basketCount, setBasketCount] = useState(0);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);  
  const [popoverMessage, setPopoverMessage] = useState('Search by artwork name, partial size, material, style, technique or year.');
  const audioRef = useRef(null);
  const lastVisitedPageRef = useRef(location.pathname);
  const searchInputRef = useRef(null); 
  const [logoUrl, setLogoUrl] = useState(''); // State to hold the logo URL

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const logoRef = ref(storageConfig, 'Balenok.com/Header Logo/logo-balenok-black.png');
        const url = await getDownloadURL(logoRef);
        setLogoUrl(url);
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };
    fetchLogo();
  }, []);

  useEffect(() => {
    setActiveNavItem(location.pathname);

    if (location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/contact') {
      lastVisitedPageRef.current = location.pathname;
    }

    if (!location.pathname.startsWith('/search')) {
      setSearchQuery('');
    }

    document.body.className = '';
    document.body.classList.add(`route-${location.pathname.replace('/', '')}`);
  }, [location.pathname]);

  useEffect(() => {
    const updateCounts = async () => {
      try {
        if (isLoggedIn) {
          const [favCount, baskCount] = await Promise.all([fetchFavoriteCount(), fetchBasketCount()]);
          setFavoriteCount(favCount || 0);
          setBasketCount(baskCount || 0);
        }
      } catch (error) {
        console.error('Error fetching counts:', error);
        setFavoriteCount(0);
        setBasketCount(0);
      }
    };

    if (isLoggedIn) {
      setTimeout(updateCounts, 100);
    } else {
      setFavoriteCount(0);
      setBasketCount(0);
    }

    const handleUpdateCounts = () => {
      updateCounts();
    };

    window.addEventListener('updateFavoritesCount', handleUpdateCounts);
    window.addEventListener('updateBasketCount', handleUpdateCounts);
    window.addEventListener('artworkDeleted', handleUpdateCounts);

    return () => {
      window.removeEventListener('updateFavoritesCount', handleUpdateCounts);
      window.removeEventListener('updateBasketCount', handleUpdateCounts);
      window.removeEventListener('artworkDeleted', handleUpdateCounts);
    };
  }, [isLoggedIn]);

  const initializePopovers = () => {
    const popoverTriggers = [
      { id: 'adminPopover', content: 'Admin panel', placement: 'left' },
      { id: 'homePopover', content: 'About artist', placement: 'left' },
      { id: 'galleryPopover', content: 'Artworks collections', placement: 'left' },
      { id: 'etchingsPopover', content: 'Graphic prints', placement: 'left' },
      { id: 'paintingsPopover', content: 'Oil on canvas', placement: 'left' },
      { id: 'registerPopover', content: 'Create your user account', placement: 'left', actionAfterPopover: () => handleNavItemClick('register') },
      { id: 'loginPopover', content: 'Access your user account', placement: 'left', actionAfterPopover: () => handleNavItemClick('login') },
      { id: 'logoutPopover', content: 'Exit your user account', placement: 'left' },
      { id: 'favoritesPopover', content: isLoggedIn ? 'View your favorites' : 'Log in to view your favorites', placement: 'top' },
      { id: 'basketPopover', content: isLoggedIn ? 'View your basket' : 'Log in to view your basket', placement: 'top' },
      { id: 'contactPopover', content: 'Contact us', actionAfterPopover: () => handleNavItemClick('contact'),  placement: 'top', },
      { id: 'musicPopover', content: "Artist's Ambience Choice", placement: 'top' }
    ];

    popoverTriggers.forEach(({ id, content, placement, actionAfterPopover }) => {
      const triggerElement = document.getElementById(id);
      if (triggerElement) {
        $(triggerElement).popover('dispose').popover({
          content,
          trigger: isTouchDevice() ? 'click' : 'hover',
          placement,
        }).on('shown.bs.popover', () => {
          if (isTouchDevice() && actionAfterPopover) {
            setTimeout(() => {
              $(triggerElement).popover('hide');
              actionAfterPopover();
            }, 600);  // Popover visible for 500 milliseconds (half a second), then form appears
          }
        });
      }
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      initializePopovers();
    }, 600);
    return () => clearTimeout(timer);
  }, [isLoggedIn, isAdmin, location.pathname]);
  
  useEffect(() => {
    if (location.pathname === '/admin') {
      const timer = setTimeout(() => {
        initializePopovers();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [location.pathname]);

  const handleFavoritesClick = (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      navigate('/favorites');
    } else {
      const popoverTrigger = document.getElementById('favoritesPopover');
      $(popoverTrigger).popover('show');
      setTimeout(() => $(popoverTrigger).popover('hide'), 1200);
    }
  };

  const handleBasketClick = (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      navigate('/basket');
    } else {
      const popoverTrigger = document.getElementById('basketPopover');
      $(popoverTrigger).popover('show');
      setTimeout(() => $(popoverTrigger).popover('hide'), 1200);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${searchQuery}`);
      setActiveNavItem('');
      setPopoverVisible(false);
    } else {
      setPopoverMessage('Please enter a search term');
      setPopoverVisible(true);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (!e.target.value.trim()) {
      setPopoverVisible(true);
    }
  };

  const handleInputFocus = () => {
    if (!searchQuery.trim()) {
      setPopoverVisible(true);
    }
  };

  const handleInputBlur = (e) => {
    if (!e.relatedTarget || e.relatedTarget.type !== 'submit') {
      setPopoverVisible(false);
    }
  };

  const handleMouseEnter = () => {
    if (!searchQuery.trim()) {
      setPopoverVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (!searchQuery.trim() && document.activeElement !== searchInputRef.current) {
      setPopoverVisible(false);
    }
  };

  const handleNavItemClick = (item) => {
    setActiveNavItem(item);
    onTogglePopup(item);
  };

  const handleMusicClick = () => {
    const popoverTrigger = document.getElementById('musicPopover');
    $(popoverTrigger).popover('show');
    setTimeout(() => $(popoverTrigger).popover('hide'), 1200);
    if (isMusicPlaying) {
      audioRef.current.pause();
      setIsMusicPlaying(false);
    } else {
      audioRef.current.play();
      setIsMusicPlaying(true);
    }
  };

  const handleClosePopup = () => {
    const lastVisitedPage = lastVisitedPageRef.current || '/';
    setActiveNavItem(lastVisitedPage);
    navigate(lastVisitedPage);
  };

  const handleLogout = () => {
    onLogout();
    navigate('/');
    initializePopovers();
    setActiveNavItem('/');
    setFavoriteCount(0);
    setBasketCount(0);
  };

  const handleToggle = () => {
    setIsActive(!isActive);
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isLoggedIn && location.pathname === '/login') {
      setActiveNavItem('/');
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isMenuOpen) {
      document.getElementById('navbarNav').classList.add('show');
    } else {
      document.getElementById('navbarNav').classList.remove('show');
    }
  }, [isMenuOpen]);

  const isGalleryPage = location.pathname === '/etchings' || location.pathname === '/paintings';

  useEffect(() => {
    window.addEventListener('closePopup', handleClosePopup);

    return () => {
      window.removeEventListener('closePopup', handleClosePopup);
    };
  }, []);

  const handleLogoClick = () => {
    navigate('/');
  };

  // Reset page state when switching between etchings and paintings
  const handleGalleryNavClick = (path) => {
    // Reset currentPage in sessionStorage for the respective gallery
    sessionStorage.removeItem(`currentPage_${path}`); // Clear the current page in session storage
    setActiveNavItem(path);
    navigate(path);
  };

  return (
    <header className="sticky-header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid px-3">
          <div className="navbar-brand" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
            {logoUrl && <img src={logoUrl} alt="Logo" className="logo" />} {/* Load logo from Firebase */}
          </div>
          <button
            className={`navbar-toggler ${isActive ? 'active' : ''}`}
            type="button"
            onClick={handleToggle}
            aria-controls="navbarNav"
            aria-expanded={isActive}
            aria-label="Toggle navigation"
            style={{ borderColor: 'darkgrey' }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20" height="25">
              <path className={`line1 ${isActive ? 'line1-active' : ''}`} stroke="darkred" strokeWidth="1" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22" />
              <path className={`line2 ${isActive ? 'line2-active' : ''}`} stroke="darkred" strokeWidth="1" strokeLinecap="round" strokeMiterlimit="10" d="M4 15h22" />
              <path className={`line3 ${isActive ? 'line3-active' : ''}`} stroke="darkred" strokeWidth="1" strokeLinecap="round" strokeMiterlimit="10" d="M4 23h22" />
            </svg>
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav mr-auto">
              {isLoggedIn && !isAdmin && (
                <li className="nav-item welcome-message">
                  Welcome, <br /> {username}!
                </li>
              )}
              {isAdmin && (
                <li className="nav-item">
                  <button
                    id="adminPopover"
                    className={`nav-link ${activeNavItem === '/admin' ? 'active' : ''}`}
                    onClick={() => {
                      navigate('/admin');
                      setActiveNavItem('/admin');
                    }}
                    data-toggle="popover"
                    title=""
                  >
                    Admin
                  </button>
                </li>
              )}
              <li className="nav-item">
                <button
                  id="homePopover"
                  className={`nav-link ${activeNavItem === '/' ? 'active' : ''}`}
                  onClick={() => {
                    navigate('/');
                    setActiveNavItem('/');
                  }}
                  data-toggle="popover"
                  title=""
                >
                  Home
                </button>
              </li>
              {!isGalleryPage && (
                <li className="nav-item">
                  <button
                    id="galleryPopover"
                    className={`nav-link ${activeNavItem === '/gallery' ? 'active' : ''}`}
                    onClick={() => {
                      navigate('/gallery');
                      setActiveNavItem('/gallery');
                    }}
                    data-toggle="popover"
                    title=""
                  >
                    Gallery
                  </button>
                </li>
              )}
              {isGalleryPage && (
                <>
                  <li className="nav-item">
                    <button
                      id="etchingsPopover"
                      className={`nav-link ${location.pathname === '/etchings' ? 'active' : ''}`}
                      onClick={() => handleGalleryNavClick('etchings')}
                      data-toggle="popover"
                      title=""
                    >
                      Etchings
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      id="paintingsPopover"
                      className={`nav-link ${location.pathname === '/paintings' ? 'active' : ''}`}
                      onClick={() => handleGalleryNavClick('paintings')}
                      data-toggle="popover"
                      title=""
                    >
                      Paintings
                    </button>
                  </li>
                </>
              )}
              {isLoggedIn ? (
                <li className="nav-item">
                  <button 
                    id="logoutPopover"
                    className="nav-link btn" onClick={handleLogout}
                    data-toggle="popover"
                    title=""
                  >
                    Logout
                  </button>
                </li>
              ) : (
                <>
                  <li className="nav-item">
                    <button
                      id="registerPopover"
                      className={`nav-link btn ${activeNavItem === 'register' ? 'active' : ''}`}
                      onClick={() => handleNavItemClick('register')}
                      data-toggle="popover"
                      title=""
                    >
                      Register
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      id="loginPopover"
                      className={`nav-link btn ${activeNavItem === 'login' ? 'active' : ''}`}
                      onClick={() => handleNavItemClick('login')}
                      data-toggle="popover"
                      title=""
                    >
                      Login
                    </button>
                  </li>
                </>
              )}
              <div className="nav-icons">
                <br />
                <li className="nav-item icon-group">
                  <button
                    id="musicPopover"
                    className={`nav-link btn ${isMusicPlaying ? 'music-active' : ''} ${activeNavItem === 'music' ? 'active' : ''}`}
                    onClick={handleMusicClick}
                    data-toggle="popover"
                    title=""
                  >
                    <FontAwesomeIcon icon={faMusic} />
                  </button>
                </li>
                <li className="nav-item icon-group">
                  <button
                    id="favoritesPopover"
                    className={`nav-link btn ${location.pathname === '/favorites' ? 'active' : ''}`}
                    onClick={handleFavoritesClick}
                    data-toggle="popover"
                    title=""
                  >
                    <FontAwesomeIcon icon={faHeart} />
                    {isLoggedIn && (
                      <span className={`badge-count ${favoriteCount === 0 ? 'count-zero' : ''}`}>
                        {favoriteCount}
                      </span>
                    )}
                  </button>
                </li>
                <li className="nav-item icon-group">
                  <button
                    id="basketPopover"
                    className={`nav-link btn ${location.pathname === '/basket' ? 'active' : ''}`}
                    onClick={handleBasketClick}
                    data-toggle="popover"
                    title=""
                  >
                    <FontAwesomeIcon icon={faShoppingBasket} />
                    {isLoggedIn && (
                      <span className={`badge-count ${basketCount === 0 ? 'count-zero' : ''}`}>
                        {basketCount}
                      </span>
                    )}
                  </button>
                </li>
                <li className="nav-item icon-group">
                  <button
                    id="contactPopover"
                    className={`nav-link btn ${activeNavItem === 'contact' ? 'active' : ''}`}
                    onClick={() => handleNavItemClick('contact')}
                    data-toggle="popover"
                    title=""
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </button>
                </li>
                <li className="nav-item icon-group">
                  <form className="form-inline search-form" onSubmit={handleSearchSubmit}>
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      ref={searchInputRef}
                      id="search-art"
                      name="searchArt"
                    />
                    {popoverVisible && (
                      <div className="popover-search">
                        {popoverMessage}
                      </div>
                    )}
                    <button className="btn" type="submit">
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </form>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <audio
        ref={audioRef}
        loop
        src="https://firebasestorage.googleapis.com/v0/b/artist-website-f082c.appspot.com/o/Balenok.com%2FMusic%2F559850__migfus20__jazz-background-music-loop.mp3?alt=media&token=4f3e1619-f93c-4673-955e-b8f5db2da64c"
      ></audio>
    </header>
  );
};

export default Header;


